import { SocietyProfileSubmissionDefintionSubmissionInstanceWorkflowActionEndpointParamsInput } from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]/[workflowActionEndpoint]";
import { SocietyWorkflowBodyInput } from "admin/src/pages/api/v1/society/[societyId]/workflow/[workflowId]/instance";
import { WorkflowInstanceStopped } from "admin/src/server/bll/workflow/instance/WorkflowInstanceRunner";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface PostSocietyProfileDefinitionSubmissionInstanceWorkflowActionEndpoint
  extends BaseApiContract {
  method: HttpMethod.POST;
  apiRoute: string;
  paramsType: SocietyProfileSubmissionDefintionSubmissionInstanceWorkflowActionEndpointParamsInput;
  bodyType: SocietyWorkflowBodyInput;
  responseType: WorkflowInstanceStopped;
}

export const postSocietyProfileDefinitionSubmissionInstanceWorkflowActionEndpointContract: PostSocietyProfileDefinitionSubmissionInstanceWorkflowActionEndpoint =
  {
    method: HttpMethod.POST,
    apiRoute:
      "/api/v1/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]/[workflowActionEndpoint]",
    paramsType:
      undefined as unknown as SocietyProfileSubmissionDefintionSubmissionInstanceWorkflowActionEndpointParamsInput,
    bodyType: undefined as unknown as SocietyWorkflowBodyInput,
    responseType: undefined as unknown as WorkflowInstanceStopped,
  };
