import { TokenView } from "admin/src/server/mappers/token/token";
import { ApiResponse } from "admin/src/ui/types/common/api-response";
import { ApiQueryConfig } from "hub/src/api/types";
const VerifyToken: ApiQueryConfig<
  ApiResponse<TokenView>,
  { societyId: string; tokenValue: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/token/{tokenValue}",
  queryKey: {
    baseQueryKey: ["VerifyToken"],
    dynamicQueryKey: ["tokenValue"],
  },
};

export { VerifyToken };
