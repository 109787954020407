import { WorkflowInstanceView } from "admin/src/server/mappers/workflow/workflow-instance";
import { ApiResponse } from "admin/src/ui/types/common/api-response";
import { ApiMutationConfig, ApiQueryConfig } from "hub/src/api/types";
import { WorkflowInstance } from "hub/src/types";

const UpsertWorkflowInstance: ApiMutationConfig<
  ApiResponse<WorkflowInstanceView>,
  { societyId: string; workflowId: string },
  Record<any, any>
> = {
  apiPath:
    "/api/v1/society/{societyId}/workflow/${workflowId}/instance/{workflowInstanceId}",
  method: "POST",
};

const GetWorkflowInstance: ApiQueryConfig<
  ApiResponse<WorkflowInstance>,
  { societyId: string; workflowInstanceId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/workflow/instance/{workflowInstanceId}",
  queryKey: {
    baseQueryKey: ["GetWorkflowInstance"],
    dynamicQueryKey: ["workflowInstanceId"],
  },
};

const UpdateWorkflowInstance: ApiMutationConfig<
  ApiResponse<WorkflowInstanceView>,
  { societyId: string; workflowInstanceId: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { payload: Record<any, any> }
> = {
  apiPath: "/api/v1/society/{societyId}/workflow/instance/{workflowInstanceId}",
  method: "PATCH",
  invalidates: [
    {
      baseQueryKey: GetWorkflowInstance.queryKey.baseQueryKey,
      dynamicQueryKey: ["workflowInstanceId"],
    },
  ],
};

const GetWorkflowInstanceScore: ApiQueryConfig<
  ApiResponse<{
    [name: string]: any;
  }>,
  { societyId: string; workflowInstanceId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/submission/{workflowInstanceId}/score",
  queryKey: {
    baseQueryKey: ["GetWorkflowInstanceScore"],
    dynamicQueryKey: ["workflowInstanceId"],
  },
};

const ScoreWorkflowInstance: ApiMutationConfig<
  void,
  {
    societyId: string;
    profileId: string;
    submissionInstanceReviewerId: string;
    scoreCardStatus?: string;
  },
  { score: string | boolean }
> = {
  method: "PUT",
  apiPath:
    "/api/v1/society/{societyId}/submission/reviewer/{profileId}/submission-review-instance/{submissionInstanceReviewerId}?scoreCardStatus={scoreCardStatus}",
  invalidates: [
    {
      baseQueryKey: GetWorkflowInstance.queryKey.baseQueryKey,
      dynamicQueryKey: ["profileId", "submissionInstanceReviewerId"],
    },
    {
      baseQueryKey: ["GetSubmissionInstanceReviewers"],
      exact: false,
    },
  ],
};

const CospeakerFormFillOut: ApiMutationConfig<
  void,
  {
    societyId: string;
    profileId: string;
    submissionInstanceReviewerId: string;
  },
  { score: string | boolean }
> = {
  method: "PUT",
  apiPath:
    "/api/v1/society/{societyId}/submission/reviewer/{profileId}/submission-review-instance/{submissionInstanceReviewerId}",
  invalidates: [
    {
      baseQueryKey: GetWorkflowInstance.queryKey.baseQueryKey,
      dynamicQueryKey: ["profileId", "submissionInstanceReviewerId"],
    },
  ],
};

export {
  CospeakerFormFillOut,
  GetWorkflowInstance,
  GetWorkflowInstanceScore,
  ScoreWorkflowInstance,
  UpdateWorkflowInstance,
  UpsertWorkflowInstance,
};
