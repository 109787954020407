interface Params {
  [key: string]: number | string | (number | string)[];
}

export const replaceTemplate = (
  template: string,
  params: Params
): { result: string; usedKeys: Set<string> } => {
  const usedKeys = new Set<string>();

  // [[...key]]
  let result = template.replace(/\[\[\.\.\.([^\]]+)\]\]/g, (match, p1) => {
    usedKeys.add(p1);
    const values = params[p1];
    if (values && Array.isArray(values) && values.length > 0) {
      return values.map((value) => String(value)).join("/");
    } else if (values) {
      return String(values);
    } else {
      return "";
    }
  });

  // [...key]
  result = result.replace(/\[\.\.\.([^\]]+)\]/g, (match, p1) => {
    usedKeys.add(p1);
    const values = params[p1];
    if (values && Array.isArray(values) && values.length > 0) {
      return values.map((value) => String(value)).join("/");
    } else if (values) {
      return String(values);
    } else {
      return match;
    }
  });

  // [[key]]
  result = result.replace(/\[\[([^\]]+)\]\]/g, (match, p1) => {
    usedKeys.add(p1);
    return params[p1] !== undefined ? String(params[p1]) : "";
  });

  // [key]
  result = result.replace(/\[([^\]]+)\]/g, (match, p1) => {
    usedKeys.add(p1);
    return params[p1] !== undefined ? String(params[p1]) : match;
  });

  return { result, usedKeys };
};

const buildQueryParams = (params: Params, usedKeys: Set<string>): string => {
  const queryParams = Object.keys(params)
    .filter((key) => !usedKeys.has(key))
    .map((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        return value
          .map(
            (v) => `${encodeURIComponent(key)}=${encodeURIComponent(String(v))}`
          )
          .join("&");
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          String(value)
        )}`;
      }
    })
    .join("&");

  return queryParams ? `?${queryParams}` : "";
};

export const buildUrl = (template: string, params: Params): string => {
  const baseUrl = process.env.REACT_APP_PILLAR_API_URL || "";
  const { result, usedKeys } = replaceTemplate(template, params);
  const queryParams = buildQueryParams(params, usedKeys);

  return `${baseUrl}${result}${queryParams}`;
};
