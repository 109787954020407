/* eslint-disable @typescript-eslint/no-explicit-any */
import { Prisma } from "@prisma/client";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getSocietyProfileDefinitionSubmissionInstanceIdContract } from "shared/api-contracts/society/societyId/profiles/profileId/submission/submissionDefinitionId/instance/submissionInstanceId";
import { postSocietyProfileDefinitionSubmissionInstanceAutosaveContract } from "shared/api-contracts/society/societyId/profiles/profileId/submission/submissionDefinitionId/instance/submissionInstanceId/autosave";
import { SessionView } from "admin/src/server/mappers/session/session";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";
import Spinner from "admin/src/ui/components/common/Spinner";
import { SurveyPreview } from "admin/src/ui/components/surveyJS/preview/SurveyPreview";
import { AfterWorkflowInstanceStoppedProps } from "admin/src/ui/components/surveyJS/types/survey-preview-types";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { getProfileId } from "hub/src/utils/sessionStorage/user";
import { ENV } from "hub/src/appConstants/env";
import { WorkflowInstancePayloadBase } from "admin/src/server/bll/workflow/instance/WorkflowInstanceRunner";
import { HubRouteConfig } from "hub/src/routes/RouteConfigWrapper";
import { submissionTemplateHelper } from "admin/src/utils/submissionTemplateHelper";
export const autoSaveApiRequest = async ({
  societyId,
  profileId,
  submissionDefinitionId,
  submissionInstanceId,
  formData,
}: {
  societyId: number;
  profileId: number;
  submissionDefinitionId: number;
  submissionInstanceId: number;
  formData: WorkflowInstancePayloadBase;
}) => {
  await newApiRequest(
    postSocietyProfileDefinitionSubmissionInstanceAutosaveContract,
    {
      params: {
        societyId: societyId,
        profileId: profileId,
        submissionDefinitionId: Number(submissionDefinitionId),
        submissionInstanceId: Number(submissionInstanceId),
      },
      body: { payload: formData },
    },
  );
};

export interface HubSubmissionInstancePageRouteParams {
  submissionInstanceId: number;
  submissionDefinitionId: number;
}

const SubmissionInstancePage = ({
  ...routeConfig
}: HubRouteConfig<HubSubmissionInstancePageRouteParams>) => {
  const history = useHistory();

  const { submissionInstanceId, submissionDefinitionId } =
    routeConfig.routeParams!;

  const societyId = getSocietyId();
  const session = useHubSessionContext();
  const queryClient = useQueryClient();
  const { data, isLoading, isSuccess, isFetching } = useQuery({
    queryKey: ["submissionInstance", submissionInstanceId],
    queryFn: async () =>
      newApiRequest(getSocietyProfileDefinitionSubmissionInstanceIdContract, {
        params: {
          societyId: societyId,
          profileId: session.profile!.id,
          submissionDefinitionId: Number(submissionDefinitionId),
          submissionInstanceId: Number(submissionInstanceId),
        },
      }),
    enabled: !!submissionInstanceId,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["submissions", "submissionInstance", submissionInstanceId],
    });
    return () => {
      queryClient.invalidateQueries({
        queryKey: ["submissions", "submissionInstance", submissionInstanceId],
      });
    };
  }, [submissionInstanceId, queryClient]);

  if (
    isLoading ||
    isFetching ||
    data === undefined ||
    data === null ||
    data?.id !== Number(submissionInstanceId)
  ) {
    return <Spinner />;
  }

  if (
    isSuccess &&
    session.profileId &&
    societyId &&
    data?.workflowInstance?.payload &&
    data?.form &&
    data?.id === Number(submissionInstanceId)
  ) {
    return (
      <>
        {data.form!.formDesign && (
          <SurveyPreview
            session={session as SessionView}
            baseUrl={ENV.REACT_APP_PILLAR_API_URL}
            overrideTitle={submissionTemplateHelper({
              submissionInstance: data,
              submissionDefinition: data.submissionDefinition,
              type: "titleTemplate",
            })}
            overrideDescription={submissionTemplateHelper({
              submissionInstance: data,
              submissionDefinition: data.submissionDefinition,
              type: "additionalInfoTemplateDetailed",
            })}
            additionalInfo={{
              profileId: getProfileId(),
              workflowInstanceId: Number(data.workflowInstance!.id),
            }}
            afterWorkflowInstanceStopped={async (
              props: AfterWorkflowInstanceStoppedProps,
            ) => {
              await queryClient.invalidateQueries({
                queryKey: ["submissions"],
              });
              if (props.workflowInstanceStopped.userhubRedirect) {
                props.sender.completedHtml = "Completed! Redirecting...";
                history.push(props.workflowInstanceStopped.userhubRedirect);
              }
            }}
            isEditView={false}
            spinner={<Spinner />}
            formDesign={{
              ...data.form.formDesign,
              submissionDefinitionId: submissionDefinitionId,
              submissionInstanceId: submissionInstanceId,
            }}
            formData={data.workflowInstance!.payload}
            autoSaveChanges={async (formData: Prisma.JsonValue) => {
              await autoSaveApiRequest({
                societyId,
                profileId: session.profileId!,
                submissionDefinitionId,
                submissionInstanceId,
                formData: formData as WorkflowInstancePayloadBase,
              });
              await queryClient.invalidateQueries({
                queryKey: ["submissions"],
              });
            }}
          />
        )}
      </>
    );
  }

  return null;
};

export default SubmissionInstancePage;
