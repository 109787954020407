import { profile_type } from "@prisma/client";
import { FormikValues } from "formik";
import { useEffect, useState } from "react";
import type { GroupBase, OptionsOrGroups } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { getSocietyDirectoryIdContract } from "shared/api-contracts/society/societyId/directory/directoryId";
import { postSocietyWorkflowInstanceContract } from "shared/api-contracts/society/societyId/workflow/workflowId/instance";
import * as Yup from "yup";
import { SessionView } from "admin/src/server/mappers/session/session";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";
import NewAppButton from "admin/src/ui/components/common/newform/NewAppButton";
import NewAppForm from "admin/src/ui/components/common/newform/NewAppForm";
import NewAppLoadingSubmitButton from "admin/src/ui/components/common/newform/NewAppLoadingSubmitButton";
import NewAppTextInput from "admin/src/ui/components/common/newform/NewAppTextInput";
import { ProfileSearchQuestionComponentData } from "admin/src/ui/components/surveyJS/shared/profile-search/ProfileSearchQuestionComponent";
import { getDisplayNameHelper } from "admin/src/utils/helpers/profile/getDisplayNameHelper";
import { ProfileSearchQuestionValues } from "../shared/profile-search/profileSearchValidationExpressions";

export type SurveyJSProfileSearchProps = {
  directoryId: number;
  workflowId: number;
  emailRequired?: boolean;
  affiliationRequired?: boolean;
  firstNameRequired?: boolean;
  lastNameRequired?: boolean;
  session: SessionView | null;
  baseUrl?: string;
  existingValue?: {
    profile_id: number;
    display_name: string;
    affiliation: string;
  };
  profileValues?: {
    profilesearchmultiple: ProfileSearchQuestionValues[];
  };
  handleChanged: (values: FormikValues) => void;
};

export type OptionsValuesSurveyJsProfileSearch = {
  value: ProfileSearchQuestionComponentData;
  label: string;
} | null;

const SurveyJSProfileSearch = ({
  directoryId,
  workflowId,
  emailRequired,
  affiliationRequired,
  firstNameRequired,
  lastNameRequired,
  session,
  existingValue,
  handleChanged,
}: SurveyJSProfileSearchProps) => {
  const [createProfile, setCreateProfile] = useState(false);
  const [newValue, setNewValue] =
    useState<OptionsValuesSurveyJsProfileSearch | null>(null);
  const [hasExistingProfile, setHasExistingProfile] = useState(false);
  const validationSchema = Yup.object({
    firstName: firstNameRequired
      ? Yup.string().required("First name is required")
      : Yup.string(),
    lastName: lastNameRequired
      ? Yup.string().required("Last name is required")
      : Yup.string(),
    affiliation: affiliationRequired
      ? Yup.string().required("Affiliation is required")
      : Yup.string(),
    email: emailRequired
      ? Yup.string().email().required("Email is required")
      : Yup.string().email(),
  });

  useEffect(() => {
    if (existingValue && !newValue) {
      setNewValue({
        value: {
          profile_id: existingValue.profile_id,
          display_name: existingValue.display_name,
          affiliation: existingValue.affiliation,
        },
        label: existingValue.display_name,
      });
    }
  }, [existingValue, newValue]);

  const handleCreateProfile = async (values: FormikValues) => {
    const response = await newApiRequest(postSocietyWorkflowInstanceContract, {
      params: {
        societyId: session?.societyId ?? 0,
        workflowId: workflowId,
      },
      body: {
        payload: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          affiliation: values.affiliation,
          profile_type: profile_type.Society_User,
        },
      },
    });

    if (response.profileId) {
      const newProfile = {
        profile: {
          profile_id: response.profileId,
          display_name: `${response.payload?.first_name} ${response.payload?.last_name}`,
          affiliation: response.payload?.affiliation,
          email: response.payload?.email,
        },
      };
      handleChanged(newProfile);
      setCreateProfile(false);
      setNewValue(null);
    }
  };

  const fetchDirectoryProfiles = async (
    inputValue: string,
    loadedOptions: OptionsOrGroups<
      OptionsValuesSurveyJsProfileSearch,
      GroupBase<OptionsValuesSurveyJsProfileSearch>
    >,
    page = 1,
  ) => {
    const pageSize = 25;
    const directoryResponse = await newApiRequest(
      getSocietyDirectoryIdContract,
      {
        params: {
          societyId: Number(session?.societyId) ?? "",
          directoryId: directoryId ?? "",
        },
      },
      {
        text: inputValue,
      },
      {
        page,
        pageSize,
      },
    );

    const options = directoryResponse.results.map((profile) => ({
      value: {
        profile_id: profile.id,
        display_name: getDisplayNameHelper(profile),
        affiliation: profile.societyUser?.affiliation ?? "",
      },
      label: getDisplayNameHelper(profile, true),
    }));

    const createNewProfileOption: OptionsValuesSurveyJsProfileSearch = {
      value: {
        profile_id: -1,
      },
      label: "Create New Profile",
    };

    const hasMore =
      directoryResponse.page <
      Math.ceil(directoryResponse.totalResults / pageSize);

    return {
      options: [createNewProfileOption, ...options],
      hasMore,
      additional: page + 1,
    };
  };

  return (
    <div>
      <NewAppForm
        className="mt-5.5"
        handleSubmit={handleCreateProfile}
        handleChanged={(values) => {
          if (existingValue) {
            setHasExistingProfile(true);
            handleChanged({
              profile: {
                profile_id: existingValue.profile_id,
                display_name: existingValue.display_name,
                affiliation: existingValue.affiliation,
              },
            });
          } else {
            handleChanged(values);
          }
        }}
        yupValidation={createProfile ? validationSchema : Yup.object()}
      >
        <span data-name="profile_id">
          <AsyncPaginate
            loadOptions={fetchDirectoryProfiles}
            isDisabled={createProfile || hasExistingProfile}
            debounceTimeout={500}
            placeholder="Search for a profile"
            value={newValue}
            onChange={(value: OptionsValuesSurveyJsProfileSearch) => {
              const { profile_id, display_name, affiliation } =
                value?.value || {};
              if (profile_id === -1) {
                setCreateProfile(true);
                setNewValue(null);
              } else {
                setNewValue(value);
                handleChanged({
                  profile: {
                    profile_id: profile_id ?? 0,
                    display_name: display_name ?? "",
                    affiliation: affiliation ?? "",
                  },
                });
              }
            }}
            additional={1}
          />
        </span>
        {createProfile && (
          <ul className="p-4 space-y-2">
            <NewAppTextInput name="firstName" label="First Name" />
            <NewAppTextInput name="lastName" label="Last Name" />
            <NewAppTextInput name="email" label="Email" />
            <NewAppTextInput name="affiliation" label="Affiliation" />
            <div className="flex justify-start">
              <NewAppLoadingSubmitButton className="bg-primary-400 hover:bg-primary-600 text-white mt-2 mr-2">
                Create
              </NewAppLoadingSubmitButton>
              <NewAppButton
                onClick={() => setCreateProfile(false)}
                className="bg-status-danger hover:bg-status-danger-tint text-white mt-2"
              >
                Cancel
              </NewAppButton>
            </div>
          </ul>
        )}
      </NewAppForm>
    </div>
  );
};

export default SurveyJSProfileSearch;
