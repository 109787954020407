import { createContext, useContext } from "react";

export interface CustomTableRouterType {
  asPath: string;
  pathname: string;
  replace: (path: string) => void;
  push: (path: string) => void;
}

const CustomTableRouterContext = createContext<CustomTableRouterType | null>(
  null,
);

const useCustomTableRouter = (): CustomTableRouterType => {
  const router = useContext(CustomTableRouterContext);
  if (router === undefined) {
    throw new Error(
      "useCustomTableRouter must be used within a CustomTableRouterContext",
    );
  }
  if (router === null) {
    throw new Error("CustomTableRouterContext is null");
  }
  return router;
};

export { useCustomTableRouter, CustomTableRouterContext };
