import { SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput } from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]";
import { SocietyWorkflowBodyInput } from "admin/src/pages/api/v1/society/[societyId]/workflow/[workflowId]/instance";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface PostSocietyProfileDefinitionSubmissionInstanceAutosave
  extends BaseApiContract {
  method: HttpMethod.POST;
  apiRoute: string;
  paramsType: SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput;
  bodyType: SocietyWorkflowBodyInput;
  responseType: void;
}

export const postSocietyProfileDefinitionSubmissionInstanceAutosaveContract: PostSocietyProfileDefinitionSubmissionInstanceAutosave =
  {
    method: HttpMethod.POST,
    apiRoute:
      "/api/v1/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]/autosave",
    paramsType:
      undefined as unknown as SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput,
    bodyType: undefined as unknown as SocietyWorkflowBodyInput,
    responseType: undefined as unknown as void,
  };
