import {
  ProfileDto,
  ProfileView,
} from "admin/src/server/mappers/profile/profile";

export const getDisplayNameHelper = (
  profile?: ProfileView | ProfileDto,
  includeAffiliation?: boolean,
): string => {
  if (
    profile?.societyUser?.firstName &&
    profile?.societyUser?.lastName &&
    includeAffiliation &&
    profile?.societyUser?.affiliation
  ) {
    return `${profile?.societyUser.firstName} ${profile?.societyUser.lastName} (${profile?.societyUser.affiliation})`;
  }
  if (profile?.societyUser?.firstName && profile?.societyUser?.lastName) {
    return `${profile?.societyUser.firstName} ${profile?.societyUser.lastName}`;
  }
  if (profile?.institution?.name) {
    return profile?.institution?.name;
  }
  return "";
};
