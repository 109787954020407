import {
  PostSocietySubmissionReviewVoteParamsInput,
  PostSocietySubmissionReviewVoteBodyInput,
} from "admin/src/pages/api/v1/society/[societyId]/review/instance/[reviewInstanceId]/assignment/[assignmentId]/vote";
import { ReviewInstanceVoteView } from "admin/src/server/mappers/submission/review/review-instance-vote";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface PostSocietyReviewInstanceAssignmentVote extends BaseApiContract {
  method: HttpMethod.POST;
  apiRoute: string;
  paramsType: PostSocietySubmissionReviewVoteParamsInput;
  bodyType: PostSocietySubmissionReviewVoteBodyInput;
  responseType: ReviewInstanceVoteView;
}

export const postSocietyReviewInstanceAssignmentVoteContract: PostSocietyReviewInstanceAssignmentVote =
  {
    method: HttpMethod.POST,
    apiRoute:
      "/api/v1/society/[societyId]/review/instance/[reviewInstanceId]/assignment/[assignmentId]/vote",
    paramsType:
      undefined as unknown as PostSocietySubmissionReviewVoteParamsInput,
    bodyType: undefined as unknown as PostSocietySubmissionReviewVoteBodyInput,
    responseType: undefined as unknown as ReviewInstanceVoteView,
  };
