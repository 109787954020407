import { SocietyProfileConferenceRegistrationWorkflowActionEndpointParamsInput } from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]/conference/[conferenceId]/registration/[conferenceRegistrationId]/[workflowActionEndpoint]";
import { SocietyWorkflowBodyInput } from "admin/src/pages/api/v1/society/[societyId]/workflow/[workflowId]/instance";
import { WorkflowInstanceStopped } from "admin/src/server/bll/workflow/instance/WorkflowInstanceRunner";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface PostSocietyProfileConferenceRegistrationWorkflowActionEndpoint
  extends BaseApiContract {
  method: HttpMethod.POST;
  apiRoute: string;
  paramsType: SocietyProfileConferenceRegistrationWorkflowActionEndpointParamsInput;
  bodyType: SocietyWorkflowBodyInput;
  responseType: WorkflowInstanceStopped;
}

export const postSocietyProfileConferenceRegistrationWorkflowActionEndpointContract: PostSocietyProfileConferenceRegistrationWorkflowActionEndpoint =
  {
    method: HttpMethod.POST,
    apiRoute:
      "/api/v1/society/[societyId]/profiles/[profileId]/conference/[conferenceId]/registration/[conferenceRegistrationId]/[workflowActionEndpoint]",
    paramsType:
      undefined as unknown as SocietyProfileConferenceRegistrationWorkflowActionEndpointParamsInput,
    bodyType: undefined as unknown as SocietyWorkflowBodyInput,
    responseType: undefined as unknown as WorkflowInstanceStopped,
  };
