import {
  TokenQuery,
  SpecialResponse,
} from "admin/src/pages/api/v1/society/[societyId]/token/[...token_value]";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface GetSocietyTokenTokenValue extends BaseApiContract {
  method: HttpMethod.GET;
  apiRoute: string;
  paramsType: TokenQuery;
  responseType: SpecialResponse;
}

export const getSocietyTokenTokenValueContract: GetSocietyTokenTokenValue = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/token/[...token_value]",
  paramsType: undefined as unknown as TokenQuery,
  responseType: undefined as unknown as SpecialResponse,
};
