import { RouteParams } from "admin/src/pages/api/v1/society/[societyId]/app-route-config";

export const mapPathParamsToUrl = (
  url: string,
  routeParms: RouteParams,
): string => {
  if (!routeParms || Object.keys(routeParms).length === 0) {
    return url;
  }

  let mappedUrl = url;

  Object.entries(routeParms).forEach(([key, value]) => {
    mappedUrl = mappedUrl.replace(`:${key}`, value!.toString());
  });

  return mappedUrl;
};
