import { SocietyProfileInvoiceInvoiceItemDiscountCodeParamsInput } from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]/item/[invoiceItemId]/discountCode/[discountCodeString]";
import { InvoiceView } from "admin/src/server/mappers/accounting/invoice/invoice";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface PostSocietyProfileAccountingInvoiceItemDiscountCodeString
  extends BaseApiContract {
  method: HttpMethod.POST;
  apiRoute: string;
  paramsType: SocietyProfileInvoiceInvoiceItemDiscountCodeParamsInput;
  responseType: InvoiceView;
}

export const postSocietyProfileAccountingInvoiceItemDiscountCodeStringContract: PostSocietyProfileAccountingInvoiceItemDiscountCodeString =
  {
    method: HttpMethod.POST,
    apiRoute:
      "/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]/item/[invoiceItemId]/discountCode/[discountCodeString]",
    paramsType:
      undefined as unknown as SocietyProfileInvoiceInvoiceItemDiscountCodeParamsInput,
    responseType: undefined as unknown as InvoiceView,
  };
