import { useHistory, useLocation } from "react-router";

import { CustomTableRouterType } from "admin/src/ui/context/CustomTableRouterContext";

export const useCustomClientTableRouter = (): CustomTableRouterType => {
  const location = useLocation();
  const history = useHistory();
  return {
    asPath: location.pathname,
    pathname: location.pathname,
    replace: (path: string) => history.replace(path),
    push: (path: string) => history.push(path),
  };
};
