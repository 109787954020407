import { SocietyProfileParamsInput } from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]";
import { ProfileView } from "admin/src/server/mappers/profile/profile";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface GetSocietyProfileId extends BaseApiContract {
  method: HttpMethod.GET;
  apiRoute: string;
  paramsType: SocietyProfileParamsInput;
  responseType: ProfileView;
}

export const getSocietyProfileIdContract: GetSocietyProfileId = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/profiles/[profileId]",
  paramsType: undefined as unknown as SocietyProfileParamsInput,
  responseType: undefined as unknown as ProfileView,
};
