import { Switch } from "@headlessui/react";
import { ExtractProps } from "admin/src/utils/helpers/prop-typing";
import { useFormikContext } from "formik";
import NewAppInput, { NewAppInputProps } from "./NewAppInput";

export type NewAppSwitchProps = NewAppInputProps &
  Omit<ExtractProps<typeof Switch>, "name" | "checked"> & {
    onChangeCallbackAction?: (currentValue?: boolean) => void;
  };

const NewAppSwitch = ({
  testid,
  name,
  label,
  labelClassName,
  additionalClasses,
  onChangeCallbackAction,
  onChange,
  className,
  ...props
}: NewAppSwitchProps) => {
  const { setFieldValue, getFieldProps } = useFormikContext();
  return (
    <NewAppInput
      name={name}
      label={label}
      labelClassName={labelClassName}
      additionalClasses={additionalClasses}
    >
      <Switch
        data-testid={testid}
        id={`switch-${name}`}
        {...getFieldProps(name)}
        checked={getFieldProps(name)?.value}
        onChange={(bool: boolean) => {
          setFieldValue(name, bool);
          if (onChangeCallbackAction) {
            onChangeCallbackAction(bool);
          } else {
            onChange?.(bool);
          }
        }}
        //TODO: Using the tailwind modifiers ui-disabled and ui-not-disabled should work in the component token class, but they do not.
        className={`appswitch-container relative inline-flex h-4 w-7 items-center ${
          props.disabled ? "appswitch-container--disabled" : ""
        } ${className ?? ""}`}
        {...props}
      >
        <span
          aria-hidden="true"
          //TODO: Using the tailwind modifiers ui-checked, ui-not-checked, ui-disabled, and ui-not-disabled should work in the component token class, but they do not.
          className={`inline-block h-2.5 w-2.5 rounded-full transform transition ${
            getFieldProps(name).value
              ? "appswitch-toggle-span-checked translate-x-3.5"
              : "appswitch-toggle-span-notchecked translate-x-0.75"
          } ${props.disabled ? "appswitch-toggle-span--disabled" : ""}`}
        />
      </Switch>
    </NewAppInput>
  );
};

export default NewAppSwitch;
