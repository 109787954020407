import { VotingQueueView } from "admin/src/server/mappers/voting/queue";
import { VotingSessionView } from "admin/src/server/mappers/voting/session";
import { ApiResponse } from "admin/src/ui/types/common/api-response";
import { ApiMutationConfig, ApiQueryConfig } from "hub/src/api/types";

const GetSubmissionQueueByVotingSessionId: ApiQueryConfig<
  ApiResponse<VotingQueueView[]>,
  { societyId: string; votingSessionId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/voting/session/{votingSessionId}/get-voting-queues",
  queryKey: {
    baseQueryKey: ["GetSubmissionQueueByVotingSessionId"],
    dynamicQueryKey: ["societyId", "votingSessionId"],
  },
};

const GetVotingSession: ApiQueryConfig<
  ApiResponse<VotingSessionView>,
  { societyId: string; votingSessionId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/voting/session/{votingSessionId}",
  queryKey: {
    baseQueryKey: ["GetVotingSession"],
    dynamicQueryKey: ["societyId", "votingSessionId"],
  },
};

const GetUserVotingSessions: ApiQueryConfig<
  ApiResponse<VotingSessionView[]>,
  { societyId: string; votingSessionId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/voting/session/get-user-voting-sessions",
  queryKey: {
    baseQueryKey: ["GetUserVotingSessions"],
    dynamicQueryKey: ["societyId", "votingSessionId"],
  },
};

const GetActiveVotingSubmissionByVotingSessionId: ApiQueryConfig<
  ApiResponse<any | null>,
  { societyId: string; votingSessionId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/voting/session/{votingSessionId}/get-active-voting-submission",
  queryKey: {
    baseQueryKey: ["GetActiveVotingSubmissionByVotingSessionId"],
    dynamicQueryKey: ["societyId", "votingSessionId"],
  },
};

const GetCurrentUserVotes: ApiQueryConfig<
  ApiResponse<any[]>,
  { societyId: string; votingSessionId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/voting/assignment/get-user-votes?votingSessionId={votingSessionId}",
  queryKey: {
    baseQueryKey: ["GetCurrentUserVotes"],
    dynamicQueryKey: ["societyId", "votingSessionId"],
  },
};

const updateUserVote: ApiMutationConfig<
  ApiResponse<void>,
  {
    societyId: string;
  },
  any
> = {
  apiPath: "/api/v1/society/{societyId}/voting/assignment/update-vote",
  method: "POST",
  invalidates: [
    {
      baseQueryKey: ["GetCurrentUserVotes"],
      exact: false,
    },
  ],
};

const GeVotingQueueById: ApiQueryConfig<
  ApiResponse<VotingQueueView>,
  { societyId: string; queueId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/voting/queue/{queueId}",
  queryKey: {
    baseQueryKey: ["GeVotingQueueById"],
    dynamicQueryKey: ["societyId", "queueId"],
  },
};

const GetSubmissionScoreCardsByInstanceId: ApiQueryConfig<
  ApiResponse<any[]>,
  { societyId: string; workflowInstanceId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/voting/submission/get-score-cards?workflowInstanceId={workflowInstanceId}",
  queryKey: {
    baseQueryKey: ["GetSubmissionScoreCardsByInstanceId"],
    dynamicQueryKey: ["societyId", "workflowInstanceId"],
  },
};

//TODO: This should be VoterPoolView  .
const GetLoggedInVoterDetails: ApiQueryConfig<
  ApiResponse<any | null>,
  { societyId: string; votingSessionId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/voting/session/{votingSessionId}/pool/get-voter-details",
  queryKey: {
    baseQueryKey: ["GetLoggedInVoterDetails"],
    dynamicQueryKey: ["societyId", "votingSessionId"],
  },
};

export {
  GeVotingQueueById,
  GetActiveVotingSubmissionByVotingSessionId,
  GetCurrentUserVotes,
  GetLoggedInVoterDetails,
  GetSubmissionQueueByVotingSessionId,
  GetSubmissionScoreCardsByInstanceId,
  GetUserVotingSessions,
  GetVotingSession,
  updateUserVote,
};
