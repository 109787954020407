import {
  SocietyWorkflowParamsInput,
  SocietyWorkflowBodyInput,
} from "admin/src/pages/api/v1/society/[societyId]/workflow/[workflowId]/instance";
import { WorkflowInstanceStopped } from "admin/src/server/bll/workflow/instance/WorkflowInstanceRunner";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface PostSocietyWorkflowInstance extends BaseApiContract {
  method: HttpMethod.POST;
  apiRoute: string;
  paramsType: SocietyWorkflowParamsInput;
  bodyType: SocietyWorkflowBodyInput;
  responseType: WorkflowInstanceStopped;
}

export const postSocietyWorkflowInstanceContract: PostSocietyWorkflowInstance =
  {
    method: HttpMethod.POST,
    apiRoute: "/api/v1/society/[societyId]/workflow/[workflowId]/instance",
    paramsType: undefined as unknown as SocietyWorkflowParamsInput,
    bodyType: undefined as unknown as SocietyWorkflowBodyInput,
    responseType: undefined as unknown as WorkflowInstanceStopped,
  };
