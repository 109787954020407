import { SocietyDirectoryParamsInput } from "admin/src/pages/api/v1/society/[societyId]/directory/[directoryId]";
import { DirectoryView } from "admin/src/server/mappers/directory/directory";
import { ProfileView } from "admin/src/server/mappers/profile/profile";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { PaginatedResults } from "admin/src/ui/types/common/general-types";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface GetSocietyDirectoryId extends BaseApiContract {
  method: HttpMethod.GET;
  apiRoute: string;
  paramsType: SocietyDirectoryParamsInput;
  responseType: PaginatedResults<ProfileView, DirectoryView>;
}
interface DeleteSocietyDirectoryId extends BaseApiContract {
  method: HttpMethod.DELETE;
  apiRoute: string;
  paramsType: SocietyDirectoryParamsInput;
  responseType: DirectoryView;
}

export const getSocietyDirectoryIdContract: GetSocietyDirectoryId = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/directory/[directoryId]",
  paramsType: undefined as unknown as SocietyDirectoryParamsInput,
  responseType: undefined as unknown as PaginatedResults<
    ProfileView,
    DirectoryView
  >,
};
export const deleteSocietyDirectoryIdContract: DeleteSocietyDirectoryId = {
  method: HttpMethod.DELETE,
  apiRoute: "/api/v1/society/[societyId]/directory/[directoryId]",
  paramsType: undefined as unknown as SocietyDirectoryParamsInput,
  responseType: undefined as unknown as DirectoryView,
};
