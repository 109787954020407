import { SocietyProfileReviewAssignmentParamsInput } from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]";
import { RecuseAssignmentBodyInput } from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]/recuse";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface PatchSocietyProfileReviewAssignmentRecuse extends BaseApiContract {
  method: HttpMethod.PATCH;
  apiRoute: string;
  paramsType: SocietyProfileReviewAssignmentParamsInput;
  bodyType: RecuseAssignmentBodyInput;
  responseType: void;
}

export const patchSocietyProfileReviewAssignmentRecuseContract: PatchSocietyProfileReviewAssignmentRecuse =
  {
    method: HttpMethod.PATCH,
    apiRoute:
      "/api/v1/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]/recuse",
    paramsType:
      undefined as unknown as SocietyProfileReviewAssignmentParamsInput,
    bodyType: undefined as unknown as RecuseAssignmentBodyInput,
    responseType: undefined as unknown as void,
  };
