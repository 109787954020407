import { Dialog, Transition } from "@headlessui/react";

import {
  ComponentPropsWithoutRef,
  Fragment,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";

export type NewAppModalBodyProps = ComponentPropsWithoutRef<"div"> & {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  containerClasses?: string;
};
export type NewAppModalHandle = {
  open: () => void;
  close: () => void;
  isOpen: boolean;
};
const NewAppModalBodyComponent = (
  {
    title,
    subtitle,
    children,
    containerClasses,
    className,
    ...props
  }: NewAppModalBodyProps,
  ref: React.ForwardedRef<NewAppModalHandle>,
) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    },
    close: () => setIsOpen(false),
    isOpen,
  }));
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-10 overflow-auto ${containerClasses ?? ""}`}
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-25"
          leave="ease-in duration-200"
          leaveFrom="opacity-25"
          leaveTo="opacity-0"
        >
          <div className="appmodal-body-background fixed inset-0" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="overflow-auto flex min-h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`appmodal-panel-container overflow-auto transform transition-all  ${
                  className ?? ""
                }`}
                {...props}
              >
                <Dialog.Title as="h3" className="appmodal-panel-title mb-0.5">
                  {title}
                </Dialog.Title>
                {subtitle && <h6 className="text-subtle mb-2">{subtitle}</h6>}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
const NewAppModalBody = forwardRef<NewAppModalHandle, NewAppModalBodyProps>(
  NewAppModalBodyComponent,
);

export default NewAppModalBody;
