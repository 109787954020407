import { IonSpinner } from "@ionic/react";
import { SessionView } from "admin/src/server/mappers/session/session";
import { SurveyPreview } from "admin/src/ui/components/surveyJS/preview/SurveyPreview";
import LoadingPage from "hub/src/components/common/LoadingPage";
import PageComponent from "hub/src/components/common/PageComponent";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { useHistory } from "react-router";
import { getProfileId } from "hub/src/utils/sessionStorage/user";
import { HubRouteConfig } from "hub/src/routes/RouteConfigWrapper";
import { id } from "ethers/lib/utils";
import { ENV } from "hub/src/appConstants/env";
import { useQuery } from "@tanstack/react-query";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";
import { getSocietyFormIdContract } from "shared/api-contracts/society/societyId/form/formId";

export type HubFormPageRouteParams = {
  formId: number;
  uiMsg?: string;
};
const FormPage = (routeConfig?: HubRouteConfig<HubFormPageRouteParams>) => {
  console.log("FormPage routeConfig", routeConfig);
  const { formId, uiMsg } = routeConfig!.routeParams!;
  const history = useHistory();
  const hubSessionContext = useHubSessionContext();
  console.log("formId", formId);

  const { data: getFormResponse, isLoading: getFormIsLoading } = useQuery(
    [`formId-${formId}`],
    async () =>
      await newApiRequest(getSocietyFormIdContract, {
        params: {
          societyId: hubSessionContext.societyId!,
          formId: formId,
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const form = !getFormResponse
    ? undefined
    : JSON.parse(JSON.stringify(getFormResponse?.formDesign));

  const responseData = JSON.parse(
    JSON.stringify(hubSessionContext.profile?.additionalInformation ?? {}),
  );

  if (getFormIsLoading) {
    return <LoadingPage />;
  }
  return (
    <PageComponent>
      <PageComponent.Header>Form</PageComponent.Header>
      <PageComponent.Content>
        {uiMsg && (
          <div className="flex justify-center p-2">
            <h4 className="font-medium w-max">{uiMsg}</h4>
          </div>
        )}
        {getFormResponse && (
          <SurveyPreview
            baseUrl={ENV.REACT_APP_PILLAR_API_URL}
            additionalInfo={{
              profileId: getProfileId(),
            }}
            profile={hubSessionContext.profile ?? undefined}
            formDesign={form}
            formData={responseData}
            formId={Number(id)}
            senderCompletedHtml="Complete."
            //FOR SEAN form data here? idk brain no work good
            afterWorkflowInstanceStopped={async () => {
              hubSessionContext.refreshSession();
              history.push("/overview");
            }}
            session={hubSessionContext}
            spinner={<IonSpinner />}
          />
        )}
      </PageComponent.Content>
    </PageComponent>
  );
};

export default FormPage;
