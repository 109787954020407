import { CompleteEvent, SurveyModel } from "survey-core";
import { WorkflowInstanceStopped } from "../../../../server/bll/workflow/instance/WorkflowInstanceRunner";
export interface AdditionalInfoSubmission {
  profileId?: number;
  submissionProcessId?: number;
  submissionResponseId?: number;
  submissionWorkflowId?: number;
  workflowComplexId?: number;
  workflowInstanceId?: number;
}

export type AfterWorkflowInstanceStoppedProps = {
  sender: SurveyModel;
  options: CompleteEvent;
  workflowInstanceStopped: WorkflowInstanceStopped;
};

export const ProfileScalarFieldEnum = {
  id: "id",
  society_id: "society_id",
  profile_picture: "profile_picture",
  status_id: "status_id",
  type: "type",
  biography: "biography",
  additional_info: "additional_info",
  created_id: "created_id",
  last_modified_id: "last_modified_id",
};

export const SocietyUserScalarFieldEnum = {
  degree: "degree",
  display_name: "display_name",
  first_name: "first_name",
  job_title: "job_title",
  last_name: "last_name",
  middle_name: "middle_name",
  prefix: "prefix",
  profession: "profession",
  research: "research",
  surname: "surname",
  affiliation: "affiliation",
  date_of_birth: "date_of_birth",
};

export const InstitutionScalarFieldEnum = {
  name: "name",
};

export const form_category = {
  NULL: "NULL",
  Travel_Award: "Travel Award",
  Conference_Submission: "Conference Submission",
  Profile: "Profile",
  Scorecard: "Scorecard",
  Disclosure: "Disclosure",
  Other: "Other",
  Institution_Membership: "Institution Membership",
  Mentor_Form: "Mentor Form",
  Mentee_Form: "Mentee Form",
  Quiz_Builder: "Quiz Builder",
  Generated_Quiz: "Generated Quiz",
  Grouped_Conference_Registration: "Grouped Conference Registration",
};

export const EventScalarFieldEnum = {
  id: "id",
  event_type_id: "event_type_id",
  conference_id: "conference_id",
  room_id: "room_id",
  workflow_instance_id: "workflow_instance_id",
  post_conference_form_id: "post_conference_form_id",
  product_id: "product_id",
  submission_workflow_id: "submission_workflow_id",
  zoom_meeting_id: "zoom_meeting_id",
  is_parent_event: "is_parent_event",
  start_buffer_minutes: "start_buffer_minutes",
  end_buffer_minutes: "end_buffer_minutes",
  display_buffer_on_schedule: "display_buffer_on_schedule",
  parent_event_id: "parent_event_id",
  additional_info: "additional_info",
  event_name: "event_name",
  is_private: "is_private",
  day_number: "day_number",
  end_time: "end_time",
  start_time: "start_time",
  expected_attendance: "expected_attendance",
  is_all_day: "is_all_day",
  is_posted: "is_posted",
  description: "description",
  additional_setup: "additional_setup",
  audience_setup: "audience_setup",
  av_setup: "av_setup",
  comments: "comments",
  configuration: "configuration",
  food_and_beverage_setup: "food_and_beverage_setup",
  signage_setup: "signage_setup",
  stage_setup: "stage_setup",
  is_hybrid: "is_hybrid",
  video_link: "video_link",
  virtual_link: "virtual_link",
  qa_enabled: "qa_enabled",
  chat_enabled: "chat_enabled",
  sequence: "sequence",
  is_package: "is_package",
  room_count: "room_count",
  primary_category: "primary_category",
  secondary_category: "secondary_category",
  event_format_type: "event_format_type",
  sponsor_name: "sponsor_name",
  sponsor_logo: "sponsor_logo",
  sponsor_level: "sponsor_level",
  zoom_meeting_link: "zoom_meeting_link",
  poster_one: "poster_one",
  poster_two: "poster_two",
  background_image: "background_image",
  avitars: "avitars",
  video_link_two: "video_link_two",
  poster_one_more_info: "poster_one_more_info",
  poster_two_more_info: "poster_two_more_info",
  faq: "faq",
  max_number_post_conference_quiz_attempts:
    "max_number_post_conference_quiz_attempts",
  show_on_external_schedule: "show_on_external_schedule",
  audio_url: "audio_url",
  poster_document: "poster_document",
  created_id: "created_id",
  last_modified_id: "last_modified_id",
};
