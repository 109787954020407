import { SpinnerSize } from "admin/src/constants/enums/spinner-sizes";
import Spinner from "admin/src/ui/components/common/Spinner";
import { useFormikContext } from "formik";
import { ComponentPropsWithoutRef } from "react";

type NewAppLoadingSubmitButtonProps = ComponentPropsWithoutRef<"button"> & {
  label?: string;
  testid?: string;
  className?: string;
  isLoading?: boolean;
};

const NewAppLoadingSubmitButton = ({
  label,
  testid,
  className,
  isLoading,
  ...props
}: NewAppLoadingSubmitButtonProps) => {
  const { handleSubmit, isSubmitting, setSubmitting } = useFormikContext();

  const handleClick = async () => {
    setSubmitting(true);
    try {
      await handleSubmit();
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <button
      data-testid={testid}
      type="button"
      className={`button-regular-general-filled w-10 ${className ?? ""}`}
      disabled={isSubmitting || isLoading}
      onClick={handleClick}
      {...props}
    >
      {isSubmitting || isLoading ? (
        <Spinner spinnerSize={SpinnerSize.Small} />
      ) : (
        <>
          {label && label}
          {props.children}
        </>
      )}
    </button>
  );
};

export default NewAppLoadingSubmitButton;
