import { useQueryClient } from "@tanstack/react-query";
import { InvoiceView } from "admin/src/server/mappers/accounting/invoice/invoice";
import { InvoiceItemView } from "admin/src/server/mappers/accounting/invoice/invoice-item";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";
import NewAppForm from "admin/src/ui/components/common/newform/NewAppForm";
import NewAppSubmitButton from "admin/src/ui/components/common/newform/NewAppSubmitButton";
import NewAppTextInput from "admin/src/ui/components/common/newform/NewAppTextInput";
import NewAppModalBody, {
  NewAppModalHandle,
} from "hub/src/components/common/newmodal/NewAppModalBody";
import { FormikValues } from "formik";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { useState } from "react";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { postSocietyProfileAccountingInvoiceItemDiscountCodeStringContract } from "shared/api-contracts/society/societyId/profiles/profileId/accounting/invoice/invoiceId/item/invoiceItemId/discountCode/discountCodeString";

interface props {
  modalRef: React.RefObject<NewAppModalHandle>;
  invoiceItem: InvoiceItemView;
  invoice: InvoiceView;
  refetchInvoice: () => void;
}

const ApplyDiscountCodeModal = ({
  modalRef,
  invoiceItem,
  invoice,
  refetchInvoice,
}: props) => {
  const [discountCode, setPromoCode] = useState("");
  const session = useHubSessionContext();
  const queryClient = useQueryClient();

  const handleApplyPromoCode = async (values: FormikValues) => {
    setPromoCode(values.discountCode);
    await newApiRequest(
      postSocietyProfileAccountingInvoiceItemDiscountCodeStringContract,
      {
        params: {
          societyId: getSocietyId(),
          profileId: session.profile!.id,
          invoiceId: invoice.invoiceId!,
          invoiceItemId: invoiceItem.id!,
          discountCodeString: values.discountCode,
        },
      },
    );
    await refetchInvoice();

    modalRef.current?.close();
  };
  return (
    <NewAppModalBody ref={modalRef} title="Apply Promo Code">
      <NewAppForm
        className="mt-1"
        handleSubmit={handleApplyPromoCode}
        handleChanged={function (changedValues: FormikValues): void {
          // throw new Error("Function not implemented.");
        }}
      >
        <div className="flex flex-col items-center ">
          <NewAppTextInput name={"discountCode"} label="Enter Promo Code" />
          <div className="flex w-full justify-end mt-2">
            <NewAppSubmitButton label="Apply" className="w-full h-6" />
          </div>
        </div>
      </NewAppForm>
    </NewAppModalBody>
  );
};

export default ApplyDiscountCodeModal;
