import { SessionView } from "admin/src/server/mappers/session/session";

import {
  ComputedUpdater,
  GetPanelFooterActionsEvent,
  SurveyModel,
} from "survey-core";
import { generatedPanelName } from "./ProfileSearchMultipleQuestion";

export type SurveyInitProfileSearchQuestion = {
  surveyModel: SurveyModel;
  session: SessionView;
};

export const surveyInitProfileSearchQuestion = async ({
  surveyModel,
  session,
}: SurveyInitProfileSearchQuestion) => {
  surveyModel.onGetPanelFooterActions.add(
    (sender: SurveyModel, options: GetPanelFooterActionsEvent) => {
      if (options.actions[0]?.visible) {
        options.actions[0].visible = new ComputedUpdater(() => {
          const panelName = options.panel.name;
          return panelName !== generatedPanelName;
        });
      }
    },
  );

  surveyModel.onUpdatePanelCssClasses.add(
    (sender: SurveyModel, options: any) => {
      if (options.panel.name === generatedPanelName) {
        options.cssClasses.panel.content = "generatedRolePanelContent";
      }
    },
  );
};
