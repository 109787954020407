import { SocietyProfileInvoiceParamsInput } from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]";
import {
  SocietyProfileInvoicePaymentBodyInput,
  ProcessPaymentOutput,
} from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]/payment";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface PostSocietyProfileAccountingInvoicePayment extends BaseApiContract {
  method: HttpMethod.POST;
  apiRoute: string;
  paramsType: SocietyProfileInvoiceParamsInput;
  bodyType: SocietyProfileInvoicePaymentBodyInput;
  responseType: ProcessPaymentOutput;
}

export const postSocietyProfileAccountingInvoicePaymentContract: PostSocietyProfileAccountingInvoicePayment =
  {
    method: HttpMethod.POST,
    apiRoute:
      "/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]/payment",
    paramsType: undefined as unknown as SocietyProfileInvoiceParamsInput,
    bodyType: undefined as unknown as SocietyProfileInvoicePaymentBodyInput,
    responseType: undefined as unknown as ProcessPaymentOutput,
  };
