import { SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput } from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]";
import { SocietyWorkflowBodyInput } from "admin/src/pages/api/v1/society/[societyId]/workflow/[workflowId]/instance";
import { SubmissionInstanceView } from "admin/src/server/mappers/submission/instance/submission-instance";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface GetSocietyProfileDefinitionSubmissionInstanceId
  extends BaseApiContract {
  method: HttpMethod.GET;
  apiRoute: string;
  paramsType: SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput;
  responseType: SubmissionInstanceView;
}
interface PostSocietyProfileDefinitionSubmissionInstanceId
  extends BaseApiContract {
  method: HttpMethod.POST;
  apiRoute: string;
  paramsType: SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput;
  bodyType: SocietyWorkflowBodyInput;
  responseType: SubmissionInstanceView;
}

export const getSocietyProfileDefinitionSubmissionInstanceIdContract: GetSocietyProfileDefinitionSubmissionInstanceId =
  {
    method: HttpMethod.GET,
    apiRoute:
      "/api/v1/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]",
    paramsType:
      undefined as unknown as SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput,
    responseType: undefined as unknown as SubmissionInstanceView,
  };
export const postSocietyProfileDefinitionSubmissionInstanceIdContract: PostSocietyProfileDefinitionSubmissionInstanceId =
  {
    method: HttpMethod.POST,
    apiRoute:
      "/api/v1/society/[societyId]/profiles/[profileId]/submission/[submissionDefinitionId]/instance/[submissionInstanceId]",
    paramsType:
      undefined as unknown as SocietyProfileSubmissionDefintionSubmissionInstanceParamsInput,
    bodyType: undefined as unknown as SocietyWorkflowBodyInput,
    responseType: undefined as unknown as SubmissionInstanceView,
  };
