import { ApiResponse } from "admin/src/ui/types/common/api-response";
import { PaginationRequest } from "admin/src/ui/types/common/general-types";
import { HttpMethod } from "admin/src/ui/types/common/http";
import { RequestHeader } from "admin/src/ui/types/common/request-header";
import { FiltersRequest } from "admin/src/utils/helpers/filter-where-clause";
import queryString from "query-string";

export const addFiltersAndPaginationToUrl = (
  url: string,
  filters: FiltersRequest,
  paginationRequest?: PaginationRequest,
) => {
  const stringified = queryString.stringifyUrl(
    {
      url: url,
      query: { ...filters, ...paginationRequest },
    },
    {
      skipNull: true,
      skipEmptyString: true,
      arrayFormat: "bracket",
    },
  );
  return stringified;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ApiRequest = async <T = any>(
  route: string,
  requestMethod: HttpMethod,
  requestBody?: object | string | number,
  customHeaders?: HeadersInit,
  customParser?: (Response: Response) => ApiResponse["body"],
  filters?: FiltersRequest,
  pagination?: PaginationRequest,
  //This was needed to support legacy code but still allow for the return of the axios response
  //In several places, our code needs to know the status or other meta of the response, not just the data.
  returnRawResponse = false,
): Promise<ApiResponse<T> | Response | null> => {
  let response;

  const isPillarAdmin = !process.env.REACT_APP_PILLAR_API_URL;

  if (filters || pagination) {
    route = addFiltersAndPaginationToUrl(route, filters ?? {}, pagination);
  }
  if (requestMethod === HttpMethod.GET) {
    response = await fetch(route, {
      credentials: "include",
      next: { revalidate: 0 },
      cache: "no-store",
      headers: { ...(isPillarAdmin ? { "pillar-admin": "true" } : {}) },
    });
  } else {
    response = await fetch(route, {
      credentials: "include",
      method: requestMethod,
      headers: {
        ...(customHeaders != undefined && customHeaders != null
          ? customHeaders
          : RequestHeader.defaultJSON),
        ...(isPillarAdmin ? { "pillar-admin": "true" } : {}),
      },
      body: JSON.stringify(requestBody),
      next: { revalidate: 0 },
      cache: "no-store",
    });
  }
  if (response.status === 204) {
    return null;
  }
  if (response.status >= 500 && response.status <= 599) {
    const responseBody = (await response.json()) as ApiResponse;
    if (responseBody.isSuccessful === false) {
      throw new Error(responseBody.message ?? "Server Error");
    }
  }

  return returnRawResponse
    ? response
    : customParser == undefined
    ? await response.json()
    : await customParser(response);
};
export default ApiRequest;
