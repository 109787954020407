import { SocietyProfileReviewAssignmentParamsInput } from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]";
import { ReviewInstanceAssignmentView } from "admin/src/server/mappers/review/reviewInstanceAssignment";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface GetSocietyProfileReviewAssignmentId extends BaseApiContract {
  method: HttpMethod.GET;
  apiRoute: string;
  paramsType: SocietyProfileReviewAssignmentParamsInput;
  responseType: ReviewInstanceAssignmentView;
}

export const getSocietyProfileReviewAssignmentIdContract: GetSocietyProfileReviewAssignmentId =
  {
    method: HttpMethod.GET,
    apiRoute:
      "/api/v1/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]",
    paramsType:
      undefined as unknown as SocietyProfileReviewAssignmentParamsInput,
    responseType: undefined as unknown as ReviewInstanceAssignmentView,
  };
