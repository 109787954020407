import { SocietyProfileInvoiceBodyInput } from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice";
import { SocietyProfileInvoiceParamsInput } from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]";
import { InvoiceView } from "admin/src/server/mappers/accounting/invoice/invoice";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface GetSocietyProfileAccountingInvoiceId extends BaseApiContract {
  method: HttpMethod.GET;
  apiRoute: string;
  paramsType: SocietyProfileInvoiceParamsInput;
  responseType: InvoiceView;
}
interface DeleteSocietyProfileAccountingInvoiceId extends BaseApiContract {
  method: HttpMethod.DELETE;
  apiRoute: string;
  paramsType: SocietyProfileInvoiceParamsInput;
  responseType: void;
}
interface PatchSocietyProfileAccountingInvoiceId extends BaseApiContract {
  method: HttpMethod.PATCH;
  apiRoute: string;
  paramsType: SocietyProfileInvoiceParamsInput;
  bodyType: SocietyProfileInvoiceBodyInput;
  responseType: undefined;
}

export const getSocietyProfileAccountingInvoiceIdContract: GetSocietyProfileAccountingInvoiceId =
  {
    method: HttpMethod.GET,
    apiRoute:
      "/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]",
    paramsType: undefined as unknown as SocietyProfileInvoiceParamsInput,
    responseType: undefined as unknown as InvoiceView,
  };
export const deleteSocietyProfileAccountingInvoiceIdContract: DeleteSocietyProfileAccountingInvoiceId =
  {
    method: HttpMethod.DELETE,
    apiRoute:
      "/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]",
    paramsType: undefined as unknown as SocietyProfileInvoiceParamsInput,
    responseType: undefined as unknown as void,
  };
export const patchSocietyProfileAccountingInvoiceIdContract: PatchSocietyProfileAccountingInvoiceId =
  {
    method: HttpMethod.PATCH,
    apiRoute:
      "/api/v1/society/[societyId]/profiles/[profileId]/accounting/invoice/[invoiceId]",
    paramsType: undefined as unknown as SocietyProfileInvoiceParamsInput,
    bodyType: undefined as unknown as SocietyProfileInvoiceBodyInput,
    responseType: undefined as unknown as undefined,
  };
