import { SocietyProfileReviewAssignmentSubmissionInstanceParamsInput } from "admin/src/pages/api/v1/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]/submission/[submissionInstanceId]/preview";
import { PreviewView } from "admin/src/server/mappers/submission/preview/preview-instance";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface GetSocietyProfileReviewAssignmentSubmissionInstancePreview
  extends BaseApiContract {
  method: HttpMethod.GET;
  apiRoute: string;
  paramsType: SocietyProfileReviewAssignmentSubmissionInstanceParamsInput;
  responseType: PreviewView;
}

export const getSocietyProfileReviewAssignmentSubmissionInstancePreviewContract: GetSocietyProfileReviewAssignmentSubmissionInstancePreview =
  {
    method: HttpMethod.GET,
    apiRoute:
      "/api/v1/society/[societyId]/profiles/[profileId]/review/assignments/[reviewAssignmentId]/submission/[submissionInstanceId]/preview",
    paramsType:
      undefined as unknown as SocietyProfileReviewAssignmentSubmissionInstanceParamsInput,
    responseType: undefined as unknown as PreviewView,
  };
