import { IonSpinner } from "@ionic/react";
import { SessionView } from "admin/src/server/mappers/session/session";
import { SurveyPreview } from "admin/src/ui/components/surveyJS/preview/SurveyPreview";
import PageComponent from "hub/src/components/common/PageComponent";
import useDeviceInfo from "hub/src/hooks/useDeviceInfo";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { ENV } from "../appConstants/env";

const NewProfilePage = () => {
  const history = useHistory();
  const session = useHubSessionContext();
  const device = useDeviceInfo();

  useEffect(() => {
    if (session.profile) {
      history.replace("/overview");
    }
  }, [session.profile]);

  return (
    <PageComponent>
      {device.isNativeApp && (
        <PageComponent.Header showBackButton={true}></PageComponent.Header>
      )}
      <PageComponent.Content>
        <SurveyPreview
          baseUrl={ENV.REACT_APP_PILLAR_API_URL}
          formDesign={session.society?.profileForm!.formDesign!}
          session={session as SessionView}
          spinner={<IonSpinner />}
          afterWorkflowInstanceStopped={async () => {
            await session.refreshSession();
            history.push("/login");
          }}
        />
      </PageComponent.Content>
    </PageComponent>
  );
};

export default NewProfilePage;
