import { SessionView } from "admin/src/server/mappers/session/session";
import elements from "admin/src/ui/components/surveyJS/shared/profile-address-contact-information/elements.json";
import { IProfileAddressContactInformationQuestion } from "admin/src/ui/components/surveyJS/shared/profile-address-contact-information/iquestion";
import { formatPhoneOrFaxNumberString } from "admin/src/ui/components/surveyJS/utils/formatPhoneOrFaxNumberString";
import { generateIteratedQuestionName } from "admin/src/ui/components/surveyJS/utils/geIteratedQuestionName";
import {
  ContactInformationUI,
  ProfileAddressUI,
} from "admin/src/ui/types/profile/profile-address-contact-information";
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  Question,
  QuestionCompositeModel,
  QuestionDropdownModel,
  Serializer,
  SurveyModel,
} from "survey-core";
import { QuestionAddedEvent, SurveyCreatorModel } from "survey-creator-core";

export const custom_question_profile_address_contact_information =
  "profileaddresscontactinformation";

export type ProfileAddressContactInformationQuestionProps = {
  creator?: SurveyCreatorModel;
  session?: SessionView;
};
export const profileAddressContactInformationQuestion = async ({
  creator,
  session,
}: ProfileAddressContactInformationQuestionProps) => {
  creator?.onQuestionAdded.add(
    (sender: SurveyCreatorModel, options: QuestionAddedEvent) => {
      if (options.question) {
        if (
          options.question?.getType() ==
          custom_question_profile_address_contact_information
        ) {
          options.question.name = generateIteratedQuestionName(
            custom_question_profile_address_contact_information,
            sender,
          );
        }
      }
    },
  );

  const formatPropertyName = (str: string) => {
    str = str.replace(/([A-Z0-9])/g, " $1");
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const singleCountryCode = [
    "United States",
    "Canada",
    "Bahamas, The",
    "Bermuda",
    "Cayman Islands",
  ];

  const contact_info_object: ICustomQuestionTypeConfiguration &
    IProfileAddressContactInformationQuestion = {
    name: custom_question_profile_address_contact_information,
    title: "Address",
    elementsJSON: elements,
    onItemValuePropertyChanged: () => {
      //null
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCreated(question: QuestionCompositeModel): void {
      //null
    },
    onInit() {
      Serializer.addProperty(
        custom_question_profile_address_contact_information,
        {
          name: "country_default",
          type: "singlevalue",
          displayName: formatPropertyName("Country"),
          category: "Default",
        },
      );
      Serializer.addProperty(
        custom_question_profile_address_contact_information,
        {
          name: "type_id",
          type: "dropdown",
          displayName: formatPropertyName("Type"),
          category: "Type & Use",
          choices:
            session?.society?.profileContactInformationType?.map((type) => ({
              text: type.description,
              value: type.id,
            })) ?? [],
        },
      );
      Serializer.addProperty(
        custom_question_profile_address_contact_information,
        {
          name: "use_id",
          type: "multiplevalues",
          displayName: formatPropertyName("Use"),
          category: "Type & Use",
          choices:
            session?.society?.profileContactInformationUse?.map((use) => ({
              text: use.description,
              value: use.id,
            })) ?? [],
        },
      );
      Serializer.addProperty(
        custom_question_profile_address_contact_information,
        {
          name: "region_default",
          type: "singlevalue",
          displayName: formatPropertyName("Region"),
          category: "Default",
        },
      );
      contact_info_object.elementsJSON.forEach((element: Question) => {
        if (
          element.name === "contact_information_id" ||
          element.name === "profile_address_id" ||
          element.name === "type_id" ||
          element.name === "use_id"
        ) {
          return;
        }
        Serializer.addProperty(
          custom_question_profile_address_contact_information,
          {
            name: `${element.name}_required`,
            type: "boolean",
            displayName: formatPropertyName(element.name + " Required"),
            default: false,
            category: "Required Fields",
          },
        );
        Serializer.addProperty(
          custom_question_profile_address_contact_information,
          {
            name: `${element.name}_visible`,
            type: "boolean",
            displayName: formatPropertyName(element.name + "  Visible"),
            default: true,
            category: "Visible Fields",
          },
        );
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSurveyVisibleChanged(sender: SurveyModel, options: any) {
      if (
        options.question.getType() ===
        custom_question_profile_address_contact_information
      ) {
        contact_info_object.setValuesFromProperties(options.question);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    onAfterRender(question: QuestionCompositeModel, htmlElement: any): void {
      //null
    },
    onLoaded(question: QuestionCompositeModel) {
      if (!question.getSurvey()) {
        return;
      }

      question.clearIfInvisible = "none";
      this.setValuesFromProperties(question);
      const survey = question.getSurvey() as SurveyModel;
      if (survey.isDesignMode) {
        this.hideProperties();
      }
      if (!survey.onVisibleChanged.hasFunc(this.onSurveyVisibleChanged)) {
        survey.onVisibleChanged.add(this.onSurveyVisibleChanged);
      }
    },
    setValuesFromProperties(question: QuestionCompositeModel) {
      this.elementsJSON.forEach((element: Question) => {
        if (
          element.name === "contact_information_id" ||
          element.name === "profile_address_id" ||
          element.name === "type_id" ||
          element.name === "use_id"
        ) {
          return;
        }
        this.setVisibilityValue(question, element.name + "_visible");
        this.setDefaultValue(question, element.name + "_default");
        this.setRequiredValue(question, element.name + "_required");
      });
      question.setValue(
        "use_id",
        question.getPropertyValue("use_id") ?? [],
        false,
        false,
      );
      question.setValue(
        "type_id",
        question.getPropertyValue("type_id") ??
          session?.society?.profileContactInformationType?.[0]?.id?.toString() ??
          "TYPE_NO_AVAILABLE",
        false,
        false,
      );
    },
    hideProperties() {
      [
        "name",
        "visible",
        "isRequired",
        "readOnly",
        "enableIf",
        "requiredIf",
        "clearIfInvisible",
        "defaultValueExpression",
        "defaultValue",
        "startWithNewLine",
        "valueName",
        "useDisplayValuesInTitle",
        "correctAnswer",
        "requiredErrorText",
        "validators",
      ].forEach((prop) => {
        Serializer.getProperty(
          custom_question_profile_address_contact_information,
          prop,
        ).visible = false;
      });
    },
    onAfterRenderContentElement(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: QuestionCompositeModel,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      element: Question,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      htmlElement: any,
    ): void {
      //null
    },
    setVisibilityValue(question: QuestionCompositeModel, propertyName: string) {
      const propertyValue = question.getPropertyValue(propertyName);
      const targetQuestion = question.contentPanel.getQuestionByName(
        propertyName.replace("_visible", ""),
      );
      if (targetQuestion.name === "us_state") {
        targetQuestion.visible =
          propertyValue &&
          question.contentPanel.getQuestionByName("country").value ===
            "United States";
        return;
      }
      if (targetQuestion.name === "state") {
        targetQuestion.visible =
          propertyValue &&
          question.contentPanel.getQuestionByName("country").value !==
            "United States";
        return;
      }
      if (targetQuestion) {
        targetQuestion.setPropertyValue("visible", propertyValue);
      }
      //maintenance note, visibility for email_option is set in the element json
    },
    setRequiredValue(question: QuestionCompositeModel, propertyName: string) {
      const questionName = propertyName.replace("_required", "");
      const targetQuestion =
        question.contentPanel.getQuestionByName(questionName);
      targetQuestion.setPropertyValue(
        "isRequired",
        question.isVisible && question.getPropertyValue(propertyName),
      );
    },
    disableDefaultValuesFromSimilarQuestions() {
      //TODO: disable default values from other questions
    },
    setDefaultValue(question: QuestionCompositeModel, propertyName: string) {
      if (!propertyName.endsWith("_default")) {
        return;
      }
      if (
        propertyName === "country_default" ||
        propertyName === "region_default"
      ) {
        const target_question = question.contentPanel.getQuestionByName(
          propertyName.replace("_default", ""),
        ) as QuestionDropdownModel;

        const value = Serializer.getObjPropertyValue(question, propertyName);

        target_question.defaultValue = value;
        return;
      }
    },
    onPropertyChanged(question: QuestionCompositeModel, propertyName: string) {
      if (
        propertyName.endsWith("_visible") ||
        propertyName.endsWith("_invisible")
      ) {
        this.setVisibilityValue(question, propertyName);
      } else if (propertyName.endsWith("_required")) {
        this.setRequiredValue(question, propertyName);
      } else if (propertyName.endsWith("_default")) {
        this.setDefaultValue(question, propertyName);
      }
    },
    getSimilarQuestions(
      question: QuestionCompositeModel,
    ): Array<QuestionCompositeModel> {
      const surveyModel = question.getSurvey() as unknown as SurveyModel;
      if (surveyModel.isDesignMode) {
        return [question];
      }
      const similarQuestion = surveyModel
        .getAllQuestions()
        .filter((similarQuestion: Question) => {
          if (
            similarQuestion.getType().toLocaleLowerCase() !==
            custom_question_profile_address_contact_information.toLocaleLowerCase()
          ) {
            return false;
          }
          return similarQuestion.isVisible;
        }) as QuestionCompositeModel[];
      return similarQuestion;
    },
    handlePhoneAndFaxFormatting(
      question: QuestionCompositeModel,
      name: string,
      newValue: string,
    ): void {
      const country = question.contentPanel.getQuestionByName("country").value;
      const isSingleCountryCode = singleCountryCode.includes(country);
      const displayFields = ["phone_display", "fax_display"];
      const fieldsToUpdate = ["phone", "fax"];

      if (displayFields.includes(name) && newValue.length) {
        const formattedValue = formatPhoneOrFaxNumberString(
          newValue,
          isSingleCountryCode,
        );
        question.contentPanel.getQuestionByName(name).value = formattedValue;
      }

      fieldsToUpdate.forEach((field) => {
        const displayFieldName = `${field}_display`;
        const displayValue =
          question.contentPanel.getQuestionByName(displayFieldName).value;
        let valueToUpdate = displayValue?.slice(0, 16);

        if (isSingleCountryCode) {
          valueToUpdate = valueToUpdate?.replace(/\D/g, "");
        }
        question.contentPanel.getQuestionByName(field).value = valueToUpdate;
      });
    },
    onValueChanged: function (
      question: QuestionCompositeModel,
      name: string,
      newValue: string | undefined,
    ): void {
      if (name === "phone_display" || name === "fax_display") {
        this.handlePhoneAndFaxFormatting(question, name, newValue!);
      }
      const isSingleCountryCode = singleCountryCode.includes(
        question.contentPanel.getQuestionByName("country").value,
      );
      const phoneFaxPlaceholder = isSingleCountryCode
        ? "X (XXX) XXX-XXXX"
        : "XXXXXXXXXX";
      question.contentPanel.getQuestionByName("phone_display").placeholder =
        phoneFaxPlaceholder;
      question.contentPanel.getQuestionByName("fax_display").placeholder =
        phoneFaxPlaceholder;

      if (name === "profileAddress" || name === "contactInformation") {
        return;
      }

      //DEV PsudoCode & Note
      // if (name === "postal_code") {
      //   Please look into the useGeocodeExpression for a hook on the "postal_code" question.
      // }

      const similarQuestions = this.getSimilarQuestions(question);
      similarQuestions.forEach((singleQuestion: QuestionCompositeModel) => {
        this.onValueChangedContactInformation(singleQuestion);
        this.onValueChangedProfileAddress(singleQuestion);
      });
    },
    onValueChangedContactInformation: (
      singleQuestion: QuestionCompositeModel,
    ): void => {
      const surveyModel = singleQuestion.getSurvey() as unknown as SurveyModel;
      const surveyData = surveyModel.getSurveyData();
      let contactInformationData: ContactInformationUI[] =
        surveyData.getValue("contactInformation");
      contactInformationData = contactInformationData ?? [];
      let singleQuestionContactInformationData:
        | ContactInformationUI
        | { question_name: string };
      if (
        !contactInformationData.find(
          (data) => data.question_name === singleQuestion.name,
        )
      ) {
        singleQuestionContactInformationData = {
          question_name: singleQuestion.name,
        };
        contactInformationData.push(singleQuestionContactInformationData);
      }
      singleQuestionContactInformationData = contactInformationData.find(
        (data) => data.question_name === singleQuestion.name,
      ) ?? {
        question_name: "ERROR",
      };
      const questionValues = singleQuestion.contentPanel.getValue();
      singleQuestionContactInformationData.email = questionValues.email;
      singleQuestionContactInformationData.phone = questionValues.phone;
      singleQuestionContactInformationData.fax = questionValues.fax;
      singleQuestionContactInformationData.phone = questionValues.phone;
      singleQuestionContactInformationData.use = questionValues.use_id;
      singleQuestionContactInformationData.type = questionValues.type_id;
      if (questionValues.contact_information_id) {
        singleQuestionContactInformationData.id =
          questionValues.contact_information_id;
      }
      surveyData.setValue(
        "contactInformation",
        contactInformationData.filter(
          (data) => Object.values(data).filter((v) => v).length > 3,
        ),
        false,
        false,
      );
    },
    onValueChangedProfileAddress: (
      singleQuestion: QuestionCompositeModel,
    ): void => {
      const surveyModel = singleQuestion.getSurvey() as unknown as SurveyModel;
      const surveyData = surveyModel.getSurveyData();
      let profileAddressData: ProfileAddressUI[] =
        surveyData.getValue("profileAddress");
      profileAddressData = profileAddressData ?? [];
      let singleQuestionProfileAddressData: ProfileAddressUI;
      if (
        !profileAddressData.find(
          (data) => data.question_name === singleQuestion.name,
        )
      ) {
        singleQuestionProfileAddressData = {
          question_name: singleQuestion.name,
        };
        profileAddressData.push(singleQuestionProfileAddressData);
      }
      singleQuestionProfileAddressData = profileAddressData.find(
        (data) => data.question_name === singleQuestion.name,
      ) ?? {
        question_name: "ERROR",
      };
      const questionValues = singleQuestion.contentPanel.getValue();
      if (questionValues.country === "United States") {
        const us_state_question =
          singleQuestion.contentPanel.getQuestionByName("us_state");
        us_state_question.visible =
          singleQuestion.getPropertyValue("us_state_visible");
        const state_question =
          singleQuestion.contentPanel.getQuestionByName("state");
        state_question.visible = false;
      } else {
        const us_state_question =
          singleQuestion.contentPanel.getQuestionByName("us_state");
        us_state_question.visible = false;
        const state_question =
          singleQuestion.contentPanel.getQuestionByName("state");
        state_question.visible =
          singleQuestion.getPropertyValue("state_visible");
      }
      singleQuestionProfileAddressData.line1 = questionValues.line1;
      singleQuestionProfileAddressData.line2 = questionValues.line2;
      singleQuestionProfileAddressData.line3 = questionValues.line3;
      singleQuestionProfileAddressData.city = questionValues.city;
      singleQuestionProfileAddressData.state =
        singleQuestionProfileAddressData.country === "United States"
          ? questionValues.us_state
          : questionValues.state;
      singleQuestionProfileAddressData.country = questionValues.country;
      singleQuestionProfileAddressData.region = questionValues.region;
      singleQuestionProfileAddressData.postal_code = questionValues.postal_code;
      if (questionValues.profile_address_id) {
        singleQuestionProfileAddressData.id = questionValues.profile_address_id;
      }
      singleQuestionProfileAddressData.use = questionValues.use_id;
      singleQuestionProfileAddressData.type = questionValues.type_id;

      surveyData.setValue(
        "profileAddress",
        profileAddressData.filter(
          (data) => Object.values(data).filter((v) => v).length > 3,
        ),
        false,
        false,
      );
    },
  };

  if (
    !ComponentCollection.Instance.getCustomQuestionByName(
      custom_question_profile_address_contact_information,
    )
  ) {
    ComponentCollection.Instance.add(contact_info_object);
  }
  return contact_info_object;
};
