import { SocietyView } from "admin/src/server/mappers/society/society";
import { fileQuestionProperties } from "./fileQuestionProperties";
import { formProperties } from "./formProperties";
import { genericQuestionProperties } from "./genericQuestionProperties";
import { globalValidationProperties } from "./globalValidationProperties";
import { registerWorkflowPropertiesSurveyJS } from "./registerWorkflowPropertiesSurveyJS";
export const registerProperties = (society: SocietyView): void => {
  formProperties();
  registerWorkflowPropertiesSurveyJS();
  genericQuestionProperties({ society });
  globalValidationProperties();
  fileQuestionProperties();
};
