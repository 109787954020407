import { SurveyJSViewMode } from "admin/src/constants";
import { WorkflowInstanceStopped } from "admin/src/server/bll/workflow/instance/WorkflowInstanceRunner";
import { ReviewInstanceVoteView } from "admin/src/server/mappers/submission/review/review-instance-vote";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";
import { SurveyPreviewProps } from "admin/src/ui/components/surveyJS/preview/SurveyPreview";
import { degree_question } from "admin/src/ui/components/surveyJS/shared/degree_question";
import { fileUploadSetup } from "admin/src/ui/components/surveyJS/shared/fileUploadSetup";
import { profileInstitutionTypeQuestion } from "admin/src/ui/components/surveyJS/shared/institution-type/question";
import { login_details_question } from "admin/src/ui/components/surveyJS/shared/login_details_question";

import { RegisterObfuscateQuestionComponent } from "admin/src/ui/components/surveyJS/shared/ObfuscateQuestionComponent";
import { onTextMarkdownApplyHtmlToSurveyContents } from "admin/src/ui/components/surveyJS/shared/onTextMarkdownApplyHtmlToSurveyContents";
import { pillar_choice } from "admin/src/ui/components/surveyJS/shared/pillar_choice";
import { RegisterPillarChoiceDropdownComponent } from "admin/src/ui/components/surveyJS/shared/PillarChoiceDropdownComponent";
import { ProductsSurveyJSQuestion } from "admin/src/ui/components/surveyJS/shared/products/ProductsSurveyJSQuestion";
import { profileAddressContactInformationQuestion } from "admin/src/ui/components/surveyJS/shared/profile-address-contact-information/question";
import { ProfileSearchMultipleQuestion } from "admin/src/ui/components/surveyJS/shared/profile-search/ProfileSearchMultipleQuestion";
import { RegisterProfileSearchQuestionComponent } from "admin/src/ui/components/surveyJS/shared/profile-search/ProfileSearchQuestionComponent";
import { profileSearchValidationExpressions } from "admin/src/ui/components/surveyJS/shared/profile-search/profileSearchValidationExpressions";
import { surveyInitProfileSearchQuestion } from "admin/src/ui/components/surveyJS/shared/profile-search/surveyInitProfileSearchQuestion";
import { profile_type_question } from "admin/src/ui/components/surveyJS/shared/profile_type";
import { registerProperties } from "admin/src/ui/components/surveyJS/shared/properties/registerProperties";
import { RegisterReactTextboxComponent } from "admin/src/ui/components/surveyJS/shared/ReactTextboxComponent";
import { setupChoicesFromPillar } from "admin/src/ui/components/surveyJS/shared/setupChoicesFromPillar";
import debounce from "lodash.debounce";
import { postSocietyProfileConferenceRegistrationWorkflowActionEndpointContract } from "shared/api-contracts/society/societyId/profiles/profileId/conference/conferenceId/registration/conferenceRegistrationId/workflowActionEndpoint";
import { postSocietyProfileDefinitionSubmissionInstanceWorkflowActionEndpointContract } from "shared/api-contracts/society/societyId/profiles/profileId/submission/submissionDefinitionId/instance/submissionInstanceId/workflowActionEndpoint";
import { postSocietyReviewInstanceAssignmentVoteContract } from "shared/api-contracts/society/societyId/review/instance/reviewInstanceId/assignment/assignmentId/vote";
import { postSocietyWorkflowInstanceContract } from "shared/api-contracts/society/societyId/workflow/workflowId/instance";
import {
  CompleteEvent,
  Model,
  SurveyModel,
  ValueChangingEvent,
} from "survey-core";

export type InitializeSurveyModelProps = {
  session: SurveyPreviewProps["session"];
  formDesign: SurveyPreviewProps["formDesign"];
  formData?: SurveyPreviewProps["formData"];
  isReadOnly?: SurveyPreviewProps["isReadOnly"];
  baseUrl?: SurveyPreviewProps["baseUrl"];
  afterWorkflowInstanceStopped?: SurveyPreviewProps["afterWorkflowInstanceStopped"];
  cancelAutoSave?: SurveyPreviewProps["cancelAutoSave"];
  autoSaveChanges?: SurveyPreviewProps["autoSaveChanges"];
  senderCompletedHtml?: SurveyPreviewProps["senderCompletedHtml"];
  completeText?: string;
};
export const initializeSurveyModel = async ({
  session,
  formDesign,
  formData,
  isReadOnly,
  completeText,
  baseUrl,
  cancelAutoSave,
  autoSaveChanges,
  afterWorkflowInstanceStopped,
  senderCompletedHtml,
}: InitializeSurveyModelProps) => {
  registerProperties(session.society!);
  await Promise.all([
    pillar_choice("cospeaker_roles", session),
    pillar_choice("research_type", session),
    pillar_choice("primary_category", session),
    pillar_choice("expertise", session),
    pillar_choice("secondary_category", session),
    login_details_question(session),
    ProductsSurveyJSQuestion({ session }),
    RegisterProfileSearchQuestionComponent(session, baseUrl!),
    profileInstitutionTypeQuestion(session.society?.institutionType ?? []),
    profile_type_question(),
    degree_question(),
    ProfileSearchMultipleQuestion({ session }),
    RegisterObfuscateQuestionComponent(),
  ]);
  RegisterReactTextboxComponent();

  RegisterPillarChoiceDropdownComponent(session);
  //This is a dependent question (on emailOption), and must be run after the concurrent promises.all
  await profileAddressContactInformationQuestion({
    session,
  });
  const model = new Model(formDesign);
  onTextMarkdownApplyHtmlToSurveyContents({ surveyModel: model });
  profileSearchValidationExpressions();
  surveyInitProfileSearchQuestion({
    surveyModel: model,
    session,
  });

  model.completeText = completeText ? completeText : "Complete";

  //Tags if Admin all tags, else only current profile tags
  const tags = session.societyAdminId
    ? session.society?.tags?.map((tag) => tag.description) ?? []
    : session.profile?.tags?.map((tag) => tag.description);
  model.setVariable("societyTags", tags);

  //IgnoreValidation admin mode
  if (session.adminMode) {
    model.ignoreValidation = true;
  }

  if (session.society) {
    setupChoicesFromPillar({
      society: session.society!,
      surveyModel: model,
      baseUrl: baseUrl,
    });
  }

  if (session.society?.societyId) {
    await fileUploadSetup({
      societyId: Number(session.society.societyId),
      profileId: session.profile?.id,
      model,
      baseUrl: baseUrl,
    });
  }

  model.clearInvisibleValues = "none";

  if (isReadOnly) {
    model.mode = SurveyJSViewMode.Read_Only;
  }
  if (model && formData && Object.keys(formData as object).length > 0) {
    model.mergeData(formData);
  }

  model.onValidateQuestion.add((sender: SurveyModel, options) => {
    const question = options.question;
    const wordLimit = question.getPropertyValue("wordLimit");
    const withSpaces = question.getPropertyValue("withSpaces");
    if (!wordLimit) {
      return;
    } else {
      const text = question.value;
      if (text) {
        let wordCount;
        if (withSpaces) {
          wordCount =
            text.split(/\s+/).length - 1 + (text.match(/\s/g) || []).length;
        } else {
          wordCount = text.trim().split(/\s+/).length;
        }
        if (wordCount > wordLimit) {
          options.error = `Word limit exceeded. Maximum ${wordLimit} words allowed. You have used ${wordCount} words ${
            withSpaces && "including the spaces"
          }.`;
        }
      }
    }
  });

  const autoSaveChangesCallback = autoSaveChanges ?? (() => Promise.resolve());
  const debouncedAutoSaveChanges = debounce(autoSaveChangesCallback, 3000, {
    leading: false,
    trailing: true,
    maxWait: 30000,
  });
  if (autoSaveChanges) {
    model.textUpdateMode = "onTyping";
    model.onValueChanging.add(
      async (sender: SurveyModel, options: ValueChangingEvent) => {
        if (sender.completedState !== "saving") {
          try {
            await debouncedAutoSaveChanges(sender.data);
          } catch (error) {
            console.error(error);
          }
        }
        return options.value;
      },
    );
  }
  model.onComplete.add(async (sender: SurveyModel, options: CompleteEvent) => {
    const {
      clearSaveMessages,
      showSaveInProgress,
      showSaveSuccess,
      showSaveError,
    } = options;
    clearSaveMessages();
    showSaveInProgress("Saving your response");
    try {
      if (cancelAutoSave) {
        //The cancelAutoSave can be passed in to potentially cancel an in-progress auto-save
        await cancelAutoSave();
      }
      //This stops the auto-save from running after the form is submitted
      debouncedAutoSaveChanges.cancel();
      //This flushes the auto-save queue, so that any pending auto-saves are run or potentially finished before the form is submitted
      await debouncedAutoSaveChanges.flush();
      if (session.profileId && !sender.data.profileId) {
        sender.setValue("profileId", session.profileId);
      }
      if (formDesign.id) {
        sender.setValue("formId", formDesign.id);
      }
      let workflowInstanceStopped:
        | WorkflowInstanceStopped
        | ReviewInstanceVoteView
        | null = null;
      sender.completedHtml =
        "Please wait processing... <br/><div class='inline-block animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] w-4 h-4' style='border-color: blue; border-right-color: transparent;'></div>";

      if (sender.data.assignmentId) {
        workflowInstanceStopped = {
          payload: await newApiRequest(
            postSocietyReviewInstanceAssignmentVoteContract,
            {
              params: {
                profileId: Number(session.profileId),
                societyId: session.society!.societyId,
                reviewInstanceId: sender.data.reviewInstanceId,
                assignmentId: sender.data.assignmentId,
              },
              body: {
                score: sender.data.score,
                voteData: sender.data,
                reviewInstanceVoteId: sender.data.reviewInstanceVoteId,
              },
            },
          ),
        };
      }

      if (sender.conferenceId && sender.conferenceRegistrationId) {
        workflowInstanceStopped = await newApiRequest(
          postSocietyProfileConferenceRegistrationWorkflowActionEndpointContract,
          {
            params: {
              societyId: session.society!.societyId,
              profileId: sender.data.profileId,
              conferenceId: sender.conferenceId,
              conferenceRegistrationId: sender.conferenceRegistrationId,
              workflowActionEndpoint: "submit",
            },
            body: { payload: sender.data },
          },
        );
      } else if (sender.submissionDefinitionId && sender.submissionInstanceId) {
        workflowInstanceStopped = await newApiRequest(
          postSocietyProfileDefinitionSubmissionInstanceWorkflowActionEndpointContract,
          {
            params: {
              societyId: session.society!.societyId,
              profileId: sender.data.profileId,
              submissionDefinitionId: sender.submissionDefinitionId,
              submissionInstanceId: sender.submissionInstanceId,
              workflowActionEndpoint: "submit",
            },
            body: { payload: sender.data },
          },
        );
      } else if (sender.workflowId) {
        workflowInstanceStopped = await newApiRequest(
          postSocietyWorkflowInstanceContract,
          {
            params: {
              societyId: session.society!.societyId,
              workflowId: sender.workflowId,
            },
            body: { payload: sender.data },
          },
        );
        if (workflowInstanceStopped.profileId) {
          sender.setValue("profileId", workflowInstanceStopped.profileId);
        }
        sender.completedHtml = senderCompletedHtml || "Completed!";
      }
      if (workflowInstanceStopped) {
        if (afterWorkflowInstanceStopped) {
          await afterWorkflowInstanceStopped({
            sender,
            options,
            workflowInstanceStopped,
          });
        } else {
          sender.completedHtml = "Completed!";
          showSaveSuccess();
        }
      } else {
        showSaveError("Unknown error.");
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        showSaveError(error.message);
      }
    }
  });
  return model;
};
