import { GroupView } from "admin/src/server/mappers/group/group";
import { GroupMemberView } from "admin/src/server/mappers/group/group-member";
import { ApiResponse } from "admin/src/ui/types/common/api-response";
import { apiClient, buildApiClient } from "hub/src/api/client";
import { ApiQueryConfig } from "hub/src/api/types";
import { getSocietyId } from "hub/src/utils/getSocietyId";

export const GetGroupRoster: ApiQueryConfig<
  ApiResponse<GroupMemberView[]>,
  { societyId: string; groupId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/groups/{groupId}/members/roster/client-roster-dashboard",
  queryKey: {
    baseQueryKey: ["getGroupRoster"],
    dynamicQueryKey: ["societyId", "groupId"],
  },
};

export const getGroupMemberByGroupIdAndSocietyId = async (
  groupId: number,
  societyUserId: number,
) => {
  try {
    buildApiClient();
    const response = await apiClient!.get(
      `/api/v1/society/${getSocietyId()}/groups/${groupId}/members/society-user/${societyUserId}`,
    );
    const { body } = response!.data;
    return body;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};
export const getGroups = async (profileId: number): Promise<GroupView[]> => {
  try {
    buildApiClient();
    const response = await apiClient!.get(
      `/api/v1/society/${getSocietyId()}/profiles/${profileId}/groups`,
    );
    const { body } = response!.data;
    return body;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};
