import {
  SocietyFormIdParamsInput,
  SocietyFormIdBodyInput,
} from "admin/src/pages/api/v1/society/[societyId]/form/[formId]";
import { FormDto } from "admin/src/server/mappers/form/form";
import { FormView } from "admin/src/server/mappers/form/form";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";

interface PostSocietyFormId extends BaseApiContract {
  method: HttpMethod.POST;
  apiRoute: string;
  paramsType: SocietyFormIdParamsInput;
  bodyType: SocietyFormIdBodyInput;
  responseType: FormView;
}
interface GetSocietyFormId extends BaseApiContract {
  method: HttpMethod.GET;
  apiRoute: string;
  paramsType: SocietyFormIdParamsInput;
  responseType: FormView;
}
interface DeleteSocietyFormId extends BaseApiContract {
  method: HttpMethod.DELETE;
  apiRoute: string;
  paramsType: SocietyFormIdParamsInput;
  responseType: FormDto;
}

export const postSocietyFormIdContract: PostSocietyFormId = {
  method: HttpMethod.POST,
  apiRoute: "/api/v1/society/[societyId]/form/[formId]",
  paramsType: undefined as unknown as SocietyFormIdParamsInput,
  bodyType: undefined as unknown as SocietyFormIdBodyInput,
  responseType: undefined as unknown as FormView,
};
export const getSocietyFormIdContract: GetSocietyFormId = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/form/[formId]",
  paramsType: undefined as unknown as SocietyFormIdParamsInput,
  responseType: undefined as unknown as FormView,
};
export const deleteSocietyFormIdContract: DeleteSocietyFormId = {
  method: HttpMethod.DELETE,
  apiRoute: "/api/v1/society/[societyId]/form/[formId]",
  paramsType: undefined as unknown as SocietyFormIdParamsInput,
  responseType: undefined as unknown as FormDto,
};
